import {combineReducers} from 'redux'

import theme from './theme';
import auth from './auth';
import institution from "./institution";
import workspace from "./workspace";
import template from "./template";

export default combineReducers({
	theme,
	auth,
	institution,
	workspace,
	template,
})