import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
	workspaceData: null,
};

const updateWorkspaceData = (state, action) => {
	return updateState(
		state,
		{
			workspaceData: action.workspaceData
		}
	)
};

const reducer = (state=initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_WORKSPACE_DATA: return updateWorkspaceData(state, action);
		default: return state;
	}
};

export default reducer;