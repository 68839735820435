import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
	templateData: null,
};

const updateTemplateData = (state, action) => {
	return updateState(
		state,
		{
			templateData: action.templateData
		}
	)
};

const reducer = (state=initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_TEMPLATE_DATA: return updateTemplateData(state, action);
		default: return state;
	}
};

export default reducer;