export const SET_THEME = 'SET_THEME';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_LOADING = 'SET_LOADING';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_UBOT_TOAST = 'SET_UBOT_TOAST';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const UPDATE_INSTITUTION_DATA = 'UPDATE_INSTITUTION_DATA';

export const UPDATE_WORKSPACE_DATA = 'UPDATE_WORKSPACE_DATA';

export const UPDATE_TEMPLATE_DATA = 'UPDATE_TEMPLATE_DATA';