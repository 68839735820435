export const TRANSLATIONS_ES = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Error de servidor interno',
    UNAUTHORIZED: 'No autorizado',
    TOKEN_EXPIRED: 'Token caducado',
    NOT_FOUND: 'Servicio no encontrado',
    METHOD_NOT_ALLOWED: 'Método de solicitud no permitido',
    USER_DUPLICATED: 'Usuario duplicado',
    BAD_REQUEST: 'Solicitud incorrecta',
    USER_NOT_FOUND: 'Usuario no encontrado',
    PHONE_REQUIRED: 'Requiere telefono',
    PHONE_DUPLICATED: 'Número de teléfono duplicado',
    EMAIL_REQUIRED: 'Correo electronico requerido',
    EMAIL_DUPLICATED: 'Correo electrónico duplicado',
    PASSWORD_REQUIRED: 'Se requiere contraseña',
    WRONG_PASSWORD: 'Contraseña incorrecta',
    INVALID_USER: 'Usuario invalido',
    NOT_EMAIL_OWNER: 'Tu no eres el propietario de este correo electrónico',
    NOT_PHONE_OWNER: 'Tu no eres el propietario de este teléfono',
    EMAIL_NOT_FOUND: 'Correo electrónico no encontrado',
    PHONE_NOT_FOUND: 'Teléfono no encontrado',
    SENDING_EMAIL_ERROR: 'Envío fallido de correo electrónico',
    SENDING_MESSAGE_ERROR: 'Envío fallido de mensaje',
    NO_PERMISSION: 'No tienes permiso para hacer esta acción',
    INSTITUTION_NOT_FOUND: 'Institución no encontrada',
    ACCOUNT_NOT_VERIFIED: 'Cuenta no verificada',
    INVALID_TOKEN: 'Código inválido',
    SOMETHING_WRONG: 'Algo salió mal, por favor, ponte en contacto con soporte técnico',
    INVALID_TEACHER: 'Profesor invalido',
    INVALID_STUDENT: 'Estudiante invalido',

    ERROR_AVATAR_FILE_SIZE: 'Tamaño del archivo muy grande',

    ACCOUNT_UPDATED_SUCCESS: 'Cuenta actualizada exitosamente',
    CONTACT_UPDATED_SUCCESS: 'Contacto actualizado exitosamente',
    UPDATE_PASSWORD_SUCCESS: 'Contraseña actualizada exitosamente',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verificación exitosa del correo electrónico',
    SEND_VERIFY_CODE_SUCCESS: 'Verificación exitosa del código',
    CREATE_OWNER_SUCCESS: 'Has agregado un nuevo propietario. El nuevo propietario tiene que verificar su cuenta',
    CREATE_ADMIN_SUCCESS: 'Has agregado un/a nuevo/a administrador/a',
    DELETE_ADMIN_SUCCESS: 'Has eliminado un/a administrador/a',
    DELETE_TEACHER_SUCCESS: 'Has eliminado un profesor',
    DELETE_TEACHER_MULTIPLE_SUCCESS: 'Has eliminado a {{number}} profesors',
    CREATE_COURSE_SUCCESS: 'Ha creado un curso, debe esperar la aprobación del administrador',
    DELETE_STUDENT_MULTIPLE_SUCCESS: 'Has eliminado a {{number}} alumnos',
    DELETE_STUDENT_SUCCESS: 'Has eliminado un estudiante',
    UPLOAD_TEACHER_MASSIVE_SUCCESS: 'La carga se está ejecutando en segundo plano, recibirá una notificación una vez completada',
    CREATE_SCALE_SUCCESS: '¡Ha creado una nueva báscula con éxito!',
    CREATE_TEACHER_SUCCESS: '¡Has creado un profesor con éxito!',
    CREATE_STUDENT_SUCCESS: '¡Has creado un estudiante con éxito!',

    // FORM VALIDATION
    fieldRequired: 'Este campo es obligatorio',
    fieldInvalid: 'Valor no válido',
    weakPassword: 'Contraseña debil',
    fieldUnconfirmed: 'Por favor confirme',
    fieldMaxLength: 'Máxima {{length}} de caracteres',

    // FORM PLACEHOLDER
    user: 'Usuario',
    password: 'Contraseña',
    emailOrPhone: 'Dirección de correo electrónico o número de teléfono celular',
    enterCode: 'Ingrese su código',
    selectArea: 'Seleccione las areas',
    selectNativeLanguage: 'Seleccione idioma nativo',
    selectOtherLanguage: 'Seleccione otros idiomas',
    select: 'Seleccionar',
    placeholderExample: 'Ejemplo: {{text}}',
    filterStudyArea: 'Filtrar el área de estudio',
    filterCareerIndependentCourse: 'Filtrar carrera o curso independiente',

    // FORM LABEL
    name: 'Primer Nombre',
    lastname: "Primer Apellido",
    secondName: "Segundo Nombre",
    secondLastname: "Segundo Apellido",
    birthday: "Fecha de Nacimiento",
    nationality: 'Nacionalidad',
    idNumber: 'Número de Identificación',
    email: 'Correo Electrónico',
    phone: 'Teléfono Celular',
    repeatPassword: 'Repita su contraseña',
    enterEmailPhone: 'Ingrese si correo electrónico o teléfono celular',
    verificationCodeToEmail: 'Un código de verificación para restaurar su contraseña fue enviado a su correo electrónico',
    enterNewPassword: 'Ingrese su nueva contraseña',
    repeatNewPassword: 'Repita su nueva contraseña',
    country: 'País',
    city: 'Ciudad',
    state: 'Estado/Provincia',
    secondNationality: 'Segunda nacionalidad',
    secondIdentification: 'Segunda identificación',
    secondPassport: 'Segundo pasaporte',
    avatarOption1: "Seleccione una imagen de su computadora (máx. 1MB)",
    avatarOption2: "Cargue imagen desde link",
    avatarOption3: "Usar imagen por default",
    actual: "Actual",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirme nueva contraseña",
    interestArea: "Áreas de interés",
    nativeLanguage: "Idioma nativo",
    otherLanguage: "Otros Idiomas",
    surname: "Apellido",
    secondSurname: "Segundo Apellido",
    address: 'Dirección',
    editImage: "Editar imagen",
    ok: "OK",
    sector: "Sector",
    subSector: "Subsector",
    numberOfStudent: "Número de estudiantes",
    noInfo: "No info",
    numberOfCourse: "Número de cursos",
    institutionName: 'Nombre de la institución',
    institutionEmail: 'Correo electrónico de la institución',
    institutionPhone: 'Número telefónico de contacto',
    dateOfOrigin: 'Fecha de creación',
    description: 'Descripción',
    googleMapLink: 'Link a Google maps',
    website: 'Sitio web',
    title: 'Título',
    buttonText: 'Botón de texto',
    buttonLink: 'Botón de link',
    studyArea: 'Áreas de estudio',
    independenceCourseCareer: 'Curso de independencia / carrera',
    filteredCourse: 'Curso filtrado',
    courseSet: 'Conjunto de cursos',
    courseImageOption1: 'Seleccione una imagen de su computadora (máx. 1MB)',
    courseImageDescription1: 'El formato de la imagen debe ser JPG o PNG y tener un tamaño superior a 300px x 150px.',
    courseImageOption2: 'Cargue imagen desde link',
    courseImageOption3: 'Usar imagen por default',
    courseCoverLetterFileDescription: 'El formato de archivo debe ser docx o pdf',
    selectIfOpenCourse: 'Seleccionar si es un curso abierto',
    yes: 'Si',
    no: 'No',
    totalAcademicHour: 'Horas académicas totales',
    equivalentCredit: 'Créditos equivalentes / ECTS',
    synchronous: 'Sincrónico',
    asynchronous: 'Asincrónico',
    classDay: 'Día de clase{{index}}',
    time: 'Tiempo',
    startTime: 'Hora de inicio',
    finishTime: 'Tiempo de finalización',
    fileFormat: 'Formato de archivo',

    // WEEKDAY
    weekday0: 'Domingo',
    weekday1: 'Lunes',
    weekday2: 'Martes',
    weekday3: 'Miércoles',
    weekday4: 'Jueves',
    weekday5: 'Viernes',
    weekday6: 'Sábado',

    // SIDE MENU
    organization: 'Organización',
    workspace: 'Espacio de trabajo',
    whatsappTemplate: 'Plantilla de WhatsApp',
    template: 'Plantilla',
    sidebarFooter: '© Todos los derechos reservados',

    // STUDY AREA NAME
    studyAreaName_art_architecture_design: 'Arte, Arquitectura y Diseño',
    studyAreaName_biological_agriculture: 'Ciencias Biológicas y Agropecuarias',
    studyAreaName_economic_business: 'Ciencias Económicas, Negocios y Management',
    studyAreaName_educational: 'Ciencias de la Educación',
    studyAreaName_engineering: 'Ingenierías',
    studyAreaName_exact: 'Ciencias Exactas',
    studyAreaName_health: 'Ciencias de la Salud',
    studyAreaName_language: 'Idiomas',
    studyAreaName_social_humanity: 'Ciencias Sociales y Humanidades',
    studyAreaName_technology: 'Tecnología',

    // COURSE USER ROLE
    courseUserRole_teacher_instructor: 'Instructor/a',
    courseUserRole_teacher_assistance: 'Profesor/a Asistente',
    courseUserRole_teacher_associate: 'Profesor/a Asociado',
    courseUserRole_teacher_professor: 'Profesor/a Titular',

    // COURSE USER PERMISSION
    courseUserPermission_manage_content: 'Crear / Cargar contenidos del curso',
    courseUserPermission_manage_activity: 'Calificar / Asistir / Comentar la actividad',
    courseUserPermission_chat: 'Chatear con el alumno',
    courseUserPermission_manage_course: 'Editar la información del curso',

    // HEADER
    notification: 'Notificaciones',
    makeAsReadAll: 'Marcar todas como leídas',
    makeAsRead: 'Marcar como leída',
    makeAsUnread: 'Hacer como no leído',
    deleteAll: 'Borrar todo',
    unread: 'No leídas',
    read: 'Leídas',
    goBackToUbotPlatform: 'Volver a Ubot',
    changeRole: 'Cambiar rol',
    academic: 'Académico',
    analytics: 'Analítica',
    communication: 'Comunicación',

    // NOTIFICATION DESCRIPTION
    notificationDescription_teacher_create_course_admin: '<b>{{creatorName}}</b> ha creado un nuevo curso y espera su aprobación',
    notificationAction_teacher_create_course_admin: 'IR AL CURSO',
    notificationDescription_admin_approve_course_teacher: '<b>{{creatorName}}</b> ha aprobado el curso <b>{{courseName}}</b>',
    notificationAction_admin_approve_course_teacher: 'IR AL CURSO',
    notificationDescription_admin_enroll_course_user: 'Te inscribiste como <b>{{ownerRoleName}}</b> en el curso <b>{{courseName}}</b>',
    notificationAction_admin_enroll_course_user: 'GO TO THE COURSE',
    notificationDescription_system_massive_teacher_admin: 'La creación masiva de <b>PROFESOR</b> se completó con éxito.',
    notificationAction_system_massive_teacher_admin: 'LISTA DE PROFESOR',
    notificationDescription_system_massive_student_admin: 'La creación masiva de <b>ESTUDIANTE</b> se completó con éxito.',
    notificationAction_system_massive_student_admin: 'LISTA DE ESTUDIANTE',

    // ROLE NAME
    roleName1: 'Propietario',
    roleName2: 'Administrador',
    roleName3: 'Profesor',
    roleName4: 'Estudiante',

    // COMMON BUTTON
    delete: 'Borrar',
    view: 'Ver',
    edit: 'Editar',
    search: "Buscar",
    next: 'Próximo',
    back: 'Atrás',
    goBack: 'Anterior',
    add: 'Agregar',
    remove: 'Eliminar',
    finish: 'Terminar',
    closePreview: 'Cerrar vista previa',
    addEditTeacher: 'Agregar / Editar profesor',
    addEditStudent: 'Agregar / editar alumno',
    download: 'Descargar',

    // HEADER
    courses: 'Cursos',
    tutorials: 'Tutoriales',
    createYourSite: 'Crear tu sitio',
    profile: 'Perfil',
    configuration: 'Configuración',
    messageAndNotification: 'Mensales y notificaciones',
    darkMode: 'Modo oscuro',
    signOut: 'Desloguearse',

    // LOGIN PAGE
    savePassword: 'Guarde la contraseña y el usuario para un inicio de sesión futuro',
    login: 'Iniciar sesión',
    welcomeToUbot: '¡Bienvenido a Super Admin Ubot!',
}