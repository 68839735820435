import {errorManagementHandler, setLoading} from "./theme";
import axios from "axios";
import {API_BASE_URL} from "../../../util/constant";
import * as actionTypes from '../actionTypes';

export const updateToken = token => {
    return {
        type: actionTypes.UPDATE_TOKEN,
        token
    }
};

export const updateUserData = userData => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData
    }
};

export const getUserData = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        dispatch(setLoading(true));
        if (token) {
            axios
                .get(`${API_BASE_URL}/superadmin/auth/userData`, {headers: {Authorization: `Bearer ${token}`}})
                .then(res => {
                    const userData = res.data.data;
                    dispatch(updateUserData(userData));
                    dispatch(setLoading(false));
                })
                .catch(e => dispatch(errorManagementHandler(e)));
        } else {
            dispatch(logout());
        }
    };
};

export const logout = () => {
    return dispatch => {
        dispatch(updateUserData(null));
        localStorage.removeItem('token');
        window.open(`/auth/login`, '_parent');
    }
};