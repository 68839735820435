import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
	token: localStorage.getItem('token') || null,
	userData: null,
};

const updateToken = (state, action) => {
	return updateState(
		state,
		{token: action.token}
	)
};

const updateUserData = (state, action) => {
	return updateState(
		state,
		{
			userData: action.userData
		}
	)
};

const reducer = (state=initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_TOKEN: return updateToken(state, action);
		case actionTypes.UPDATE_USER_DATA: return updateUserData(state, action);
		default: return state;
	}
};

export default reducer;