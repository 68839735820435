import {useEffect} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loadable from "react-loadable";

import {notification} from "antd";
import UbotToast from "./components/Wrapper/UbotToast";
import Loader from "./components/Loader/Loader";

import {setMessage} from "./service/store/action/theme";

import './App.less';

const loading = () => <><Loader/></>;

const AuthLayout = Loadable({
    loader: () => import('./layouts/AuthLayout/AuthLayout'),
    loading,
});

const MainLayout = Loadable({
    loader: () => import('./layouts/MainLayout/MainLayout'),
    loading,
});

function App() {

    const dispatch = useDispatch();
    const message = useSelector((state) => {
        return {
            type: state.theme.messageType,
            content: state.theme.messageContent,
        };
    });
    const ubotMessage = useSelector(state => {
        return {type: state.theme.ubotToastType, content: state.theme.ubotToastContent};
    });

    useEffect(() => {
        if (message.content !== null) {
            notification[message.type]({
                message: message.type.toUpperCase(),
                description: message.content,
                onClose: () => dispatch(setMessage(null, null)),
            });
        }
    }, [message.content]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="App">
            <UbotToast visible={!!ubotMessage.content} type={ubotMessage.type} content={ubotMessage.content}/>
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" component={AuthLayout}/>
                    <Route path="/" component={MainLayout}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
