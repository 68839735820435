export const TRANSLATIONS_EN = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Internal server error',
    UNAUTHORIZED: 'Unauthorized',
    TOKEN_EXPIRED: 'Token expired',
    NOT_FOUND: 'Service not found',
    METHOD_NOT_ALLOWED: 'Request method not allowed',
    USER_DUPLICATED: 'Duplicated user',
    BAD_REQUEST: 'Bad request',
    USER_NOT_FOUND: 'User not found',
    PHONE_REQUIRED: 'Phone required',
    PHONE_DUPLICATED: 'Duplicated phone number',
    EMAIL_REQUIRED: 'Email required',
    EMAIL_DUPLICATED: 'Duplicated email',
    PASSWORD_REQUIRED: 'Password required',
    WRONG_PASSWORD: 'Wrong password',
    INVALID_USER: 'Invalid user',
    NOT_EMAIL_OWNER: 'You are not owner of this email',
    NOT_PHONE_OWNER: 'You are not owner of this phone',
    EMAIL_NOT_FOUND: 'Email not found',
    PHONE_NOT_FOUND: 'Phone not found',
    SENDING_EMAIL_ERROR: 'Sending email failed',
    SENDING_MESSAGE_ERROR: 'Sending message failed',
    NO_PERMISSION: 'You have no permission to do this action',
    INSTITUTION_NOT_FOUND: 'Institution not found',
    ACCOUNT_NOT_VERIFIED: 'Account not verified',
    INVALID_TOKEN: 'Invalid token',
    SOMETHING_WRONG: 'Something went wrong, please contact with support',
    INVALID_TEACHER: 'Invalid teacher',
    INVALID_STUDENT: 'Invalid student',

    ERROR_AVATAR_FILE_SIZE: 'File size is too large',

    ACCOUNT_UPDATED_SUCCESS: 'Account updated successfully',
    CONTACT_UPDATED_SUCCESS: 'Contact updated successfully',
    UPDATE_PASSWORD_SUCCESS: 'Password updated successfully',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verify email sent successfully',
    SEND_VERIFY_CODE_SUCCESS: 'Verify code sent successfully',
    CREATE_OWNER_SUCCESS: 'You have added a new owner. The new owner has to verify the account',
    CREATE_ADMIN_SUCCESS: 'You have added a new administrator',
    DELETE_ADMIN_SUCCESS: 'You have deleted an administrator',
    DELETE_TEACHER_SUCCESS: 'You have deleted a teacher / trainer',
    DELETE_TEACHER_MULTIPLE_SUCCESS: 'You have deleted {{number}} teachers / trainers',
    CREATE_COURSE_SUCCESS: 'You have created a course, you must wait for the administrator approval',
    DELETE_STUDENT_MULTIPLE_SUCCESS: 'You have deleted {{number}} students',
    DELETE_STUDENT_SUCCESS: 'You have deleted a student',
    UPLOAD_TEACHER_MASSIVE_SUCCESS: 'Upload is running in background, you will receive a notification after completed',
    CREATE_SCALE_SUCCESS: 'You have created a new scale successfully!',
    CREATE_TEACHER_SUCCESS: 'You have created a teacher successfully!',
    CREATE_STUDENT_SUCCESS: 'You have created a student successfully!',

    // FORM VALIDATION
    fieldRequired: 'This field is required',
    fieldInvalid: 'Invalid value',
    weakPassword: 'Weak password',
    fieldUnconfirmed: 'Confirm field correctly',
    fieldMaxLength: 'Maximum {{length}} characters',

    // FORM PLACEHOLDER
    user: 'User',
    password: 'Password',
    emailOrPhone: 'E-mail address or Cellphone number',
    enterCode: 'Enter your code',
    selectArea: 'Select areas',
    selectNativeLanguage: 'Select native language',
    selectOtherLanguage: 'Select other language',
    select: 'Select',
    placeholderExample: 'Example: {{text}}',
    filterStudyArea: 'Filter study area',
    filterCareerIndependentCourse: 'Filter career or independent course',

    // FORM LABEL
    name: 'Name',
    lastname: "Last name",
    secondName: "Second name",
    surname: "Surname",
    secondLastname: "Second last name",
    secondSurname: "Second surname",
    birthday: "Birth date",
    nationality: 'Nationality',
    idNumber: 'ID number',
    email: 'Email',
    phone: 'Mobile',
    repeatPassword: 'Repeat password',
    enterEmailPhone: 'Enter your email, phone',
    verificationCodeToEmail: 'A verification code to retrieve the password was sent to your email',
    enterNewPassword: 'Enter your new password',
    repeatNewPassword: 'Repeat your new password',
    country: 'Country',
    city: 'City',
    address: 'Address',
    state: 'State',
    secondNationality: 'Second nationality',
    secondIdentification: 'Second identification',
    secondPassport: 'Second passport',
    avatarOption1: "Select image from computer (maximum 1MB)",
    avatarOption2: "Load image from link",
    avatarOption3: "Use default image",
    editImage: "Edit image",
    ok: "OK",
    actual: "Actual",
    newPassword: "New password",
    confirmPassword: "Confirm new password",
    interestArea: "Interest areas",
    nativeLanguage: "Native language",
    otherLanguage: "Other language",
    sector: "Sector",
    subSector: "Sub - Sector",
    numberOfStudent: "Number of students",
    noInfo: "No info",
    numberOfCourse: "Number of courses",
    institutionName: 'Institution name',
    institutionEmail: 'Institution email',
    institutionPhone: 'Contact phone number',
    dateOfOrigin: 'Date of origin',
    description: 'Description',
    googleMapLink: 'Link to google map',
    website: 'Website',
    title: 'Title',
    buttonText: 'Button text',
    buttonLink: 'Button link',
    studyArea: 'Study area',
    independenceCourseCareer: 'Independence course / career',
    filteredCourse: 'Filtered course',
    courseSet: 'Course set',
    courseImageOption1: 'Upload file from your computer ({{size}}MB max)',
    courseImageDescription1: 'The image format must be JPG or PNG and be larger than 300px x 150px.',
    courseImageOption2: 'Upload file from link',
    courseImageOption3: 'Use default image',
    courseCoverLetterFileDescription: 'The file format must be docx or pdf',
    selectIfOpenCourse: 'Select if it is a open course',
    yes: 'Yes',
    no: 'No',
    totalAcademicHour: 'Total academic hours',
    equivalentCredit: 'Equivalent credits / ECTS',
    synchronous: 'Synchronous',
    asynchronous: 'Asynchronous',
    classDay: 'Class day{{index}}',
    time: 'Time',
    startTime: 'Start time',
    finishTime: 'Finish time',
    fileFormat: 'File format',

    // WEEKDAY
    weekday0: 'Sunday',
    weekday1: 'Monday',
    weekday2: 'Tuesday',
    weekday3: 'Wednesday',
    weekday4: 'Thursday',
    weekday5: 'Friday',
    weekday6: 'Saturday',

    // SIDE MENU
    organization: 'Organization',
    workspace: 'Workspace',
    whatsappTemplate: 'WhatsApp template',
    template: 'Template',
    sidebarFooter: '© All rights reserved',

    // STUDY AREA NAME
    studyAreaName_art_architecture_design: 'Art, Architecture and Design',
    studyAreaName_biological_agriculture: 'Biological and Agricultural Sciences',
    studyAreaName_economic_business: 'Economic Sciences and Business Management',
    studyAreaName_educational: 'Educational Sciences',
    studyAreaName_engineering: 'Engineering',
    studyAreaName_exact: 'Exact Sciences',
    studyAreaName_health: 'Health Sciences',
    studyAreaName_language: 'Languages',
    studyAreaName_social_humanity: 'Social Sciences and Humanities',
    studyAreaName_technology: 'Technology',

    // COURSE USER ROLE
    courseUserRole_teacher_instructor: 'Instructor',
    courseUserRole_teacher_assistance: 'Assistant Professor/Teacher',
    courseUserRole_teacher_associate: 'Associate Professor/Teacher',
    courseUserRole_teacher_professor: 'Professor/Teacher',

    // COURSE USER PERMISSION
    courseUserPermission_manage_content: 'Create / Upload contents of course',
    courseUserPermission_manage_activity: 'Qualify / Take attendance / Comment on activity',
    courseUserPermission_chat: 'Chat with student',
    courseUserPermission_manage_course: 'Edit course info',

    // HEADER
    notification: 'Notification',
    makeAsReadAll: 'Make as read all',
    makeAsRead: 'Make as read',
    makeAsUnread: 'Make as unread',
    deleteAll: 'Delete all',
    unread: 'Unread',
    read: 'Read',
    goBackToUbotPlatform: 'Go back to Ubot platform',
    changeRole: 'Change role',
    academic: 'Academic',
    analytics: 'Analytics',
    communication: 'Communication',

    // NOTIFICATION
    notificationDescription_teacher_create_course_admin: '<b>{{creatorName}}</b> has created a new course and wait for your approval',
    notificationAction_teacher_create_course_admin: 'GO TO THE COURSE',
    notificationDescription_admin_approve_course_teacher: '<b>{{creatorName}}</b> has approved the course <b>{{courseName}}</b>',
    notificationAction_admin_approve_course_teacher: 'GO TO THE COURSE',
    notificationDescription_admin_enroll_course_user: 'You were enrolled as <b>{{ownerRoleName}}</b> on the course <b>{{courseName}}</b>',
    notificationAction_admin_enroll_course_user: 'GO TO THE COURSE',
    notificationDescription_system_massive_teacher_admin: 'The massive creation of  <b>TEACHER</b> was finished successfully',
    notificationAction_system_massive_teacher_admin: 'TEACHER LIST',
    notificationDescription_system_massive_student_admin: 'The massive creation of  <b>STUDENT</b> was finished successfully',
    notificationAction_system_massive_student_admin: 'STUDENT LIST',

    // ROLE NAME
    roleName1: 'Owner',
    roleName2: 'Admin',
    roleName3: 'Teacher',
    roleName4: 'Student',

    // COMMON BUTTON
    delete: 'Delete',
    view: 'View',
    edit: 'Edit',
    search: "Search",
    next: 'Next',
    back: 'Back',
    goBack: 'Go back',
    add: 'Add',
    remove: 'Remove',
    finish: 'Finish',
    closePreview: 'Close preview',
    addEditTeacher: 'Add / Edit teacher',
    addEditStudent: 'Add / Edit student',
    download: 'Download',

    // HEADER
    courses: 'Courses',
    tutorials: 'Tutorials',
    createYourSite: 'Create your site',
    profile: 'Profile',
    configuration: 'Configuration',
    messageAndNotification: 'Messages and notifications',
    darkMode: 'Dark mode',
    signOut: 'Logout',

    // LOGIN PAGE
    savePassword: 'Save password and user for a future login',
    login: 'Login',
    welcomeToUbot: 'Welcome to Super Admin Ubot!',
}