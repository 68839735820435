export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LMS_URL = process.env.REACT_APP_LMS_URL;

export const COMMUNICATION_URL = process.env.REACT_APP_COMMUNICATION_URL;


export const templateTypeObj = {
    '-1': 'Invisible', 
    
    '0': 'Normal',

    '1': 'Survey start',
    '2': 'Student chat start',
    '3': 'Broadcast',
    
    '4': 'Blackboard discussion',
    '7': 'Blackboard announcement',

    '5': 'Survey expired',
    '11': 'Evaluation message with qualification',
    '12': 'Evaluation message without qualification',

    '6': 'Interaction alert message warning',
    '13': 'Interaction alert message final',
    '8': 'Performance alert message warning',
    '14': 'Performance alert message final',
    '9': 'Pass rate alert message warning',
    '15': 'Pass rate alert message final',
    '10': 'Activity deliver status alert message warning',
    '16': 'Activity deliver status alert message final',

    '17': 'Activity alert',
    '26': 'Activity alert for task',

    '18': 'DUOC Online alert green1',
    '19': 'DUOC Online alert yellow1',
    '20': 'DUOC Online alert yellow2',
    '21': 'DUOC Online alert yellow3',
    '22': 'DUOC Online alert red1',
    '23': 'DUOC Online alert red2',
    '24': 'DUOC Online alert red3',
    '25': 'DUOC Online alert red4',

    '27': 'Welcome message',
};